/* GENERAL */
@import "./variables.scss";
@import "./general.scss";
/* PAGES */
@import "./admin.scss";
@import "./historysingle.scss";
@import "./login.scss";
@import "./profile.scss";
@import "./subjectlist.scss";
@import "./subjectsingle.scss";

