#subject-list {
    .subject-container {
        width:100%;
    }
    .subject {
        display:block;
        width: 400px;
        border:1px solid #a6a6a6;
        padding:0;
        margin: 1em;
        float:left;

        .title {
            font-family: 'Signika Negative', sans-serif;
            font-weight: 400;
            font-size:1.5em;
            text-align: center;
            width:100%;
            background-color: #555;
            color:#fff;
            margin-top:0;
            padding: 0.33em 0;
        }

        img {
            max-width: 100%;
        }

        &:hover {
            border-color:$importantColor;
            cursor:pointer;
            .title {
                background-color: $importantColor;
            }
            img {
                filter:brightness(0.75);
            }
        }
    }
}