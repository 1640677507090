/******************************************************
    HISTORY LIST
*******************************************************/
#admin-history-list {
    .user {
        margin:2em 0 1em;
        font-size: 1.25em;
    }

    .history {
        border: 1px solid #a6a6a6;
        width: 500px;

        tr {
            border-bottom:1px solid #a6a6a6;

            &:nth-child(even) {
                background: #f0f0f0
            }

            td {
                padding: 1em;
            }

            &.history-row {
                &:hover {
                    background-color:#d6d6d6;
                    cursor: pointer;
                }
            }
        }

        .no-history {
            font-style: italic;;
            color:#666;
        }
    }
}

/******************************************************
    HISTORY SINGLE
*******************************************************/
#admin-history-single {

    #meta {
        padding-bottom:1em;
        border-bottom:1px solid #a6a6a6;

        span {
            display:block;
            padding:0.25em 0;
        }

        .date, .user {
            display:inline-block;
            margin-right:1.5em;
        }
        .score {
            display:inline-block;

            .value {
                color:$fontColor;
                font-weight: $bold;
                font-size: 1.25em;
            }
        }
    }

    #questions {
        .question {
            padding-bottom: 3em;

            .question-label {
                margin-bottom:0;
            }

            .points {
                font-style: italic;
                margin-bottom: 1em;
            }

            .answers {
                .answer {
                    .value {
                        color: $importantColor;
                    }

                    &.good {
                        display:none;
                    }

                    &.user {
                        .value::after {
                            content: " ✔";
                            display:inline;
                            font-weight: $bold;
                        }
                    }
                }
            }

            &.error {
                .question-label{
                    color:$errorColor;
                }

                .answers {
                    .answer.user {
                        .value {
                            color:$errorColor;
                        }

                        .value::after {
                            content: " ✘";
                            display:inline;
                        }
                    }

                    .answer.good {
                        display:block;
                        margin-top:0.5em;
                    }
                }
            }
        }
    }

}