#subject-single {
    button {
        padding:0.5em 1em;
        margin-top:1em;
        float:right;
        cursor: pointer;

        &#btnBack, &#btnSubmitBack {
            float:left;
        }

        &#btnStart {
            float:none;
        }
    }

    #timer {
        font-size: 0.5em;
        color:$fontColor;
    }

    .loader {
        float:right;
    }

    .history {
        border: 1px solid #a6a6a6;
        width: 500px;

        tr {
            border-bottom:1px solid #a6a6a6;

            &:nth-child(even) {
                background: #f0f0f0
            }

            td {
                padding: 1em;
            }

            &.history-row {
                &:hover {
                    background-color:#d6d6d6;
                    cursor: pointer;
                }
            }
        }

        .no-history {
            font-style: italic;;
            color:#666;
        }
    }

    #home {
        #btnStart {
            padding:0.5em 1em;
            margin-top:3em;
            cursor: pointer;
        }
    }

    #exam {
        .question {
            &#question-template {
                display:none;
            }
            img {
                max-width: 500px;
            }
            .choices {
                display:block;
                max-width:500px;

                .choice {
                    display:block;
                    padding:1em;
                    margin:0.5em 0.5em 0.5em 0;
                    border:1px solid #a6a6a6;
                    text-decoration: none;
                    color: $fontColor;

                    .fraction {
                        .inside {
                            bottom: -23px;
                        }
                    }

                    .letter {
                        color:$importantColor;
                    }

                    &.selected {
                        background-color:$importantColor;
                        color:#fff;

                        .letter {
                            color:#fff;
                        }

                        .fraction {
                            .inside {
                                .top {
                                    border-bottom-color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        .buttonContainer{
            display:block;
            max-width:490px;
        }
    }

    #submit {
        display:block;
        max-width: 500px;

        p {
            clear:both;
        }
    }

    #results {
        #score {
            font-size: 2.5em;
        }
    }
}