/* Font */
$fontSize: 16px;
$fontColor: #333333;
$importantColor: #2dafd6;
$errorColor: #a82323;
$bold: 700;
$regular: 400;

/* Background */
$backgroundColor: #FFFFFF;
