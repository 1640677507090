html, body {
    margin:0;
    padding:0;
    font-family: 'Roboto';
    font-size: $fontSize;
    color: $fontColor;
    background-color: $backgroundColor !important;

    height:auto;

    .hide {
        display:none !important;
    }

    select {
        width:100%;
        padding:10px;
        border:1px solid #E3E3E3;
        border-radius: 4px;
        background-color:#fff;
        font-size: $fontSize;
        font-family: 'Roboto';
    }

    input {
        padding: 0.5em;
        font-size: $fontSize;
        font-family: 'Roboto';
    }

    input[type=text], input[type=password] {
        width:100%;
    }

    .button {
        padding: 0.25em 0.5em;
        border:1px solid $importantColor;
        color:$importantColor;
        text-decoration: none;

        &:hover {
            background-color: $importantColor;
            color:#fff;
            cursor: pointer;
        }
    }

    .message {
        background-color: #c6c6c6;
        padding:0.5em;

        &.success {
            color:#116625;
            background-color: #9fe3af;
        }

        &.error {
            color:#850808;
            background-color: #ebbcbc;
        }
    }

    .nav-menu {
        display:block;
        width:100%;
        background-color:#555;
        border-bottom: 0.2em solid $importantColor;

        a {
            display:inline-block;
            text-decoration: none;
            padding:0 1em;
            line-height: 50px;
            vertical-align: middle;

            border-right: 1px solid #333;
            margin:0;

            color:#fff;
            float:left;

            &.pull-right {
                float:right;
                border-left: 1px solid #333;
            }

            &:hover {
                background-color:#999;
                cursor:pointer;
            }

            &.title {
                width: 8em;
                font-family: 'Signika Negative', sans-serif;
                font-weight: 400;
                font-size:1.5em;
                padding:0 0 0 1em;

                &:hover {
                    background-color:#555;
                    cursor:pointer;
                }
            }
        }
    }

    #content {
        margin:1em;
    }

    h1, h2, h3, h4, h5 {
        color:$importantColor;
        font-family: 'Signika Negative', sans-serif;
        font-weight: 400;
    }

    .fraction {
        display:inline-block;
        position:relative;
        .inside {
            /*position:absolute;
            bottom:-10px;*/
            margin-top:-10px;
            display:block;
            height: 50px;
            text-align: center;

            .full {
                display:inline-block;
                line-height: 50px;
                vertical-align: middle;
                padding:0 0.25em 20px;
                font-size: 1.25em;
            }

            .content {
                display:inline-block;
                height: 50px;

                .top, .bottom {
                    display:block;
                    height: 24px;
                    padding:0 0.25em;
                }
            }

            .top {
                border-bottom: 1px solid #a6a6a6;
            }
        }
    }
}