#profile {
    .control {
        margin:1em 0;
        .notice {
            font-style: italic;
            display:block;
            font-size:0.8em;
        }
        .label {
            margin:0.5em 0 0.25em;
        }
        input {
            max-width: 20em;
        }
    }

    .section-split {
        margin:4em 0;
    }

    #btnSave {
        padding: 0.5em 1em;
    }
}