#login {
    max-width: 350px;
    border:1px solid #a6a6a6;
    margin:10em auto 0;

    h1 {
        font-family: 'Signika Negative', sans-serif;
        font-weight: 400;
        font-size:2em;
        text-align: center;
        width:100%;
        background-color: #555;
        color:#fff;
        margin-top:0;
        padding: 0.33em 0;
        border-bottom: 0.2em solid $importantColor;
        margin-bottom:1em;
    }

    .message {
        text-align: center;
    }

    .body {
        padding:0 1em 0.5em;

        .action {
            text-align: center;
        }
    }
}